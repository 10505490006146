import React from 'react';
import {withRouter} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  table: {
    minWidth: 650,
  },
});


class Alarms extends React.Component {
    
    constructor(props){
        super(props);
        
        this.state = {
            event_selected: -1,
            events : [],
            event : [], //[{"timestamp":"2021-04-04 20:48:40","wristbands":{"22":{"devices":["24:a1:60:4e:ef:26"]}}},{"timestamp":"2021-04-04 20:48:50","wristbands":{"24":{"devices":["24:a1:60:4e:ef:26"]}}},{"timestamp":"2021-04-04 20:49:00","wristbands":{"24":{"devices":["24:a1:60:4e:ef:26"]}}},{"timestamp":"2021-04-04 20:49:10","wristbands":{"22":{"devices":["24:a1:60:4e:ef:26"]}}}],
            beacons : [], //[{"id":"22","x":"140","y":"185"},{"id":"23","x":"275","y":"185"},{"id":"24","x":"265","y":"295"},{"id":"25","x":"275","y":"100"},{"id":"26","x":"380","y":"140"},{"id":"27","x":"510","y":"185"},{"id":"28","x":"380","y":"520"}],
            current_step: 0,
            play : true
        };
    }
    
    componentDidMount() {
        this.canvas = this.refs.canvas;
        this.ctx = this.canvas.getContext("2d");
        this.img = this.refs.image;
        this.img.onload = () => {
            this.ctx.drawImage(this.img, 0, 0);
        }
        
        let url = '/units/event-list.php';
        fetch(url, {  
            method: 'GET'
        })
        .then((response) => {
            return response.json()
        }) 
        .then((responseData) => {
            this.setState({events:responseData});
        })         
        .catch((error) => {
            console.log('Request failure: ', error);  
        });
    }

    componentWillUnmount() {
        if(this.timeout != null){
            clearTimeout(this.timeout);
        }
    }
    
    startAnimation = () => {
        this.setState({current_step: 0, play: true}, function(){
            clearTimeout(this.timeout);
            this.timeout = setInterval(() => {
                if(this.state.play){
                    let currentStep = this.state.current_step
                    if(currentStep+1 >= this.state.event.length){
                        currentStep = 0;
                        clearTimeout(this.timeout);
                        return;
                    }else{
                        currentStep += 1;
                    }
                    this.setState({current_step : currentStep});
                    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    this.ctx.drawImage(this.img, 0, 0);
                    let currentEvent = this.state.event[currentStep];
                    this.state.beacons.forEach(beacon => {
                        let x = parseInt(beacon.x);
                        let y = parseInt(beacon.y);
                        this.ctx.beginPath();
                        this.ctx.arc(x, y, 15, 0, 2 * Math.PI);
                        this.ctx.fillStyle = 'blue';
                        this.ctx.lineWidth = 1;
                        this.ctx.strokeStyle = 'blue';
                        this.ctx.fill();
                        this.ctx.stroke();
                        this.ctx.font = "19px Courier";
                        this.ctx.fillStyle = "white";
                        this.ctx.textAlign = "center";
                        this.ctx.textBaseline = "middle";
                        let wristbands = 0;
                        if(currentEvent != null && currentEvent.beacons[beacon.id] != null){
                            console.log(currentEvent.beacons[beacon.id].wristbands)
                            wristbands = currentEvent.beacons[beacon.id].wristbands.length;
                        }
                        this.ctx.fillText(wristbands, x, y);
                    });
                }
            }, 1000);
        });
    }
    
    playPause = (e) => {        
        if(this.state.current_step+1 >= this.state.event.length){
            this.startAnimation();
        }else{
            this.setState({play : !this.state.play});
        }
    }
    
    handleChange = (e) => {
        /*clearTimeout(this.timeout);
        this.timeout = setInterval(() => {
            let currentStep = this.state.current_step
            if(currentStep+1 >= this.state.event.length){
                currentStep = 0;
            }else{
                currentStep += 1;
            }
            this.setState({current_step : currentStep});
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.ctx.drawImage(this.img, 0, 0);
            let currentEvent = this.state.event[currentStep];
            this.state.beacons.forEach(beacon => {
                let x = parseInt(beacon.x);
                let y = parseInt(beacon.y);
                this.ctx.beginPath();
                this.ctx.arc(x, y, 15, 0, 2 * Math.PI);
                this.ctx.fillStyle = 'blue';
                this.ctx.lineWidth = 1;
                this.ctx.strokeStyle = 'blue';
                this.ctx.fill();
                this.ctx.stroke();
                this.ctx.font = "19px Courier";
                this.ctx.fillStyle = "white";
                this.ctx.textAlign = "center";
                this.ctx.textBaseline = "middle";
                let wristbands = 0;
                if(currentEvent.beacons[beacon.id] != null){
                    console.log(currentEvent.beacons[beacon.id].wristbands)
                    wristbands = currentEvent.beacons[beacon.id].wristbands.length;
                }
                this.ctx.fillText(wristbands, x, y);
            });
        }, 1000);*/
        /*clearTimeout(this.timeout);
        this.timeout = setInterval(() => {
            console.log("Entrato");
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.ctx.drawImage(this.img, 0, 0);
            let x = Math.floor((Math.random() * 500) + 1);
            let y = Math.floor((Math.random() * 500) + 1);
            this.ctx.beginPath();
            this.ctx.arc(x, y, 15, 0, 2 * Math.PI);
            this.ctx.fillStyle = 'blue';
            this.ctx.lineWidth = 1;
            this.ctx.strokeStyle = 'blue';
            this.ctx.fill();
            this.ctx.stroke();
            this.ctx.font = "19px Courier";
            this.ctx.fillStyle = "white";
            this.ctx.textAlign = "center";
            this.ctx.textBaseline = "middle";
            this.ctx.fillText("1", x, y);
        }, 1000);*/
        if(e.target.value > 0){
            this.setState({event_selected: e.target.value}, function(){
                let url = '/units/history.php?event_id='+this.state.event_selected;
                fetch(url, {  
                    method: 'GET'
                })
                .then((response) => {
                    return response.json()
                }) 
                .then((responseData) => {
                    this.setState({event:responseData.history != null ? responseData.history : [], beacons:responseData.beacons != null ? responseData.beacons : [], current_step: 0}, function(){
                        this.startAnimation();
                    });
                })         
                .catch((error) => {
                    console.log('Request failure: ', error);  
                });
            });
        }else{
            this.setState({event_selected: e.target.value,
                event : [],
                beacons : [],
                current_step: 0
            });
        }
    }
    
    render(){
        const { classes } = this.props;
        
        let events = this.state.events.map(event => {
            return <MenuItem value={event.id}>{event.name}</MenuItem>
        });
        
        return (<div>
          <FormControl className={classes.formControl} style={{width:"100%"}}>
            <InputLabel id="demo-simple-select-label">Event</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.event_selected}
              onChange={this.handleChange}
            >
            <MenuItem value={-1}>Nessuno</MenuItem>
            {events}
            {/*<MenuItem value={1}>Primo</MenuItem>
              <MenuItem value={2}>Secondo</MenuItem>
            <MenuItem value={3}>Terzo</MenuItem>*/}
            </Select>
          </FormControl>
          {/*<div>{this.state.event.length > 0 && JSON.stringify(this.state.event)}</div>*/}
          <div>{this.state.event.length > 0 && this.state.event[this.state.current_step].timestamp}</div>
          {this.state.event.length > 0 && <Button onClick={this.playPause} variant="contained" color="primary" style={{marginTop:"15px"}}>{!this.state.play ? "Play" : (this.state.current_step+1 >= this.state.event.length ? "Restart" : "Pause")}</Button>}
          <div style={{width: "100%", textAlign:"center"}}>
            <canvas ref="canvas" width={1200} height={702} style={{display:"inline"}} />
            <img ref="image" src="ponti_evacuazione.jpg" style={{display:"none"}} />
          </div>
        </div>
        );
    }
}

export default withRouter(withStyles(styles)(Alarms))