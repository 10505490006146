import React from 'react';
import {withRouter} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  table: {
    minWidth: 650,
  },
});


class Alarms extends React.Component {
    
    constructor(props){
        super(props);
        
        this.state = {
            routes: [],
            action: 1
        };
    }
    
    componentDidMount() {
        
        let url = '/units/route-list.php';
        fetch(url, {  
            method: 'GET'
        })
        .then((response) => {
            return response.json()
        }) 
        .then((responseData) => {
            this.setState({routes:responseData});
        })         
        .catch((error) => {
            console.log('Request failure: ', error);  
        });
    }

    componentWillUnmount() {
        if(this.timeout != null){
            clearTimeout(this.timeout);
        }
    }
    
    handleChange = (e) => {
        clearTimeout(this.timeout);
        this.setState({action: e.target.value}, function(){
            let url = '/units/send-alarm.php?route_id='+this.state.action+'&is_new=1';
            fetch(url, {  
                method: 'GET'
            })
            .then((response) => {
                return response.json()
            }) 
            .then((responseData) => {
                console.log(responseData);
            })         
            .catch((error) => {
                console.log('Request failure: ', error);  
            });
            
            this.timeout = setInterval(() => {
                if(this.state.action > 1){
                    console.log("ciao");
                    let url = '/units/send-alarm.php?route_id='+this.state.action;
                    fetch(url, {  
                        method: 'GET'
                    })
                    .then((response) => {
                        return response.json()
                    }) 
                    .then((responseData) => {
                        console.log(responseData);
                    })         
                    .catch((error) => {
                        console.log('Request failure: ', error);  
                    });
                }
            }, 10000);
        });
    }
    
    render(){
        const { classes } = this.props;
        
        let routes = this.state.routes.map(route => {
            return <MenuItem value={route.id}>{route.name}</MenuItem>
        });
        
        return (<div>
          <FormControl className={classes.formControl} style={{width:"100%"}}>
            <InputLabel id="demo-simple-select-label">Action</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.action}
              onChange={this.handleChange}
            >
            {routes}
            </Select>
          </FormControl>
        </div>
        );
    }
}

export default withRouter(withStyles(styles)(Alarms))